import React, { useState } from 'react';
import useWebSocket from 'react-use-websocket';
import './App.css';

function App() {
  const [username, setUsername] = useState('');
  const [state, setState] = useState('start');
  const [players, setPlayers] = useState([]);
  const [seconds_remaining, setSecondsRemaining] = useState(0);
  const [question, setQuestion] = useState('');
  const [question_type, setQuestionType] = useState(''); 
  const [options_final, setOptionsFinal] = useState([]);
  const [question_timer, setQuestionTimer] = useState(0);
  const [chatMessages, setChatMessages] = useState([]);
  const [chatMessage, setChatMessage] = useState('');
  const [ranking, setRanking] = useState([]);
  const [winners, setWinners] = useState([]);
  const [points, setPoints] = useState(0);
  const [question_id, setQuestionId] = useState('');
  const [opcionSeleccionada, setOpcionSeleccionada] = useState('');
  const [streakname, setStreakname] = useState(null);
  const [streakpoints, setStreakpoints] = useState(0);
  const [correct, setCorrect] = useState(null);
  const [button_disabled, setButtonDisabled] = useState(false);


  const { sendMessage } = useWebSocket('wss://trivia.tallerdeintegracion.cl/connect', {
    onOpen: () => {
      // La conexión WebSocket se abre al cargar la página.
    },
    onMessage: (event) => {
      const message = JSON.parse(event.data);
      if (message.type === 'accepted') {
        setState('lobby'); // Cambiar a la sala de espera después de unirte con éxito.
      } 
      if (message.type === 'lobby') {
        setSecondsRemaining(message.seconds_remaining);
        setPlayers(message.players);
      }
      if (message.type === 'question') {
        setState('question');
        setChatMessages([]);
        setQuestionId(message.question_id);
        setQuestion(message.question_title);
        setQuestionType(message.question_type); 
        if (message.question_type === 'button') {
          const options = [];
          for (const [key, value] of Object.entries(message.question_options)) {
            options.push(value);
          }
          setOptionsFinal(options);
        }
        setCorrect(null);
        setStreakname(null);
        setButtonDisabled(false);
      }
      if (message.type === 'timer'){
        setQuestionTimer(message.seconds_remaining)
      }
      if (message.type === 'chat'){
        //Si el question id es distinto al de la pregunta actual, limpio los mensajes anteriories
        const currentTime = new Date().toLocaleTimeString();
        // Crear un nuevo mensaje
        const newMessage = {
          username: message.username,
          message: message.message,
          time: currentTime,
        };
        setChatMessages([...chatMessages, newMessage]);
      }
      if (message.type === 'score'){
        //el message.scores es un objeto con cada jugador y su puntaje, debo agregar a la lista de ranking cada jugador como objeto 
        const scores = [];
        for (const [key, value] of Object.entries(message.scores)) {
          const newScore = {
            username: key,
            score: value,
          }
          scores.push(newScore);
        }
        //Ordeno la lista de jugadores por puntaje
        scores.sort(function(a, b) {
          return b.score - a.score;
        });
        setRanking(scores);
        
      }
      if (message.type === 'highscore'){
        //Recibo una lista con los tres ganadores de la partida en orden, cada posicion de la lista es un objeto
        setWinners(message.winners);
        setState('end');
      }
      if (message.type === 'result'){
        if (message.correct === true){
          setPoints(points + 1);
          setCorrect(true);
        }
        else{
          setCorrect(false);
        }
      }
      if (message.type === 'streak'){
        setStreakname(message.username);
        setStreakpoints(message.streak);
      }
    },
  });

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleJoin = () => {
    // Enviar un mensaje de unión al servidor cuando se presiona el botón "Jugar".
    const payload = {
      type: 'join',
      id: '19641966',
      username: username,
    };
    sendMessage(JSON.stringify(payload));
  };

  //Funcion que envia la respuesta del usuario al servidor, al apretar los botones respectivos en cada pregunta
  const handleAnswer = (event, option) => {
    event.preventDefault();
    // Verifica si la opción actual coincide con la opción seleccionada
    if (option === opcionSeleccionada) {
      // Si es la misma opción, deselecciona la opción
      setOpcionSeleccionada(null);
    } else {
      // Si es una opción diferente, selecciona la nueva opción
      setOpcionSeleccionada(option);
    }

    const payload = {
      type: 'answer',
      question_id: parseInt(question_id), // Usa el question_id del estado
      value: option,
    };
    setButtonDisabled(true);
    setChatMessage('');
    sendMessage(JSON.stringify(payload));
  };


  return (
    <div className="container">
      {state === 'start' ? (
        <div className="start-container">
          <h1 className="medieval-title">Dungeons & Trivia</h1>
          <div className="form-container">
            <input
              type="text"
              placeholder="Nombre de Usuario"
              value={username}
              onChange={handleUsernameChange}
            />
            <button onClick={handleJoin}>Jugar</button>
          </div>
        </div>
      ) : state === 'lobby' ? (
        <div className="lobby-container">
          <h1 className="medieval-title">Lobby</h1>
          <p className='medieval-subtitle'>{`Waiting ${seconds_remaining} seconds for other players to join`}</p>
          <ul className="my-list">
            {players.map((player, index) => (
              <li key={index}>{player}</li>
            ))}
          </ul>
        </div>
      ) : state === 'question' ? (
        <div className = "alfa_question_container">
            <div className = "trivia-title">
                <h1 className="medieval-subtitle_2">Dungeons & Trivia</h1>
            </div>
            <div className = "streak-container">
                {streakname !== null ? (
                  <div className="streak-message">{streakname} is on a {streakpoints} questions streak!</div>
                ) : null}
            </div> 
            <div className='Correct-container'>
              {/* Renderiza la respuesta como correcta o incorrecta en función de la variable de estado */}
              {correct !== null && (
                <div className={`respuesta ${correct ? 'correcta' : 'incorrecta'}`}>
                  Respuesta: {correct ? 'Correcta' : 'Incorrecta'}
                </div>
            )} 
            </div>
            <div className="question-container">
              <h1 className="medieval-subtitle_question">{question}</h1>
              
              <div className="timer">Time left: {question_timer}</div>
              {question_type === 'chat' ? (
                <div className="chat-container">
                <div className="chat-messages">
                  {chatMessages.map((message, index) => (
                    <div key={index} className="chat-message">
                      <span className="chat-time">{message.time}</span>
                      <span className="chat-username">{message.username}:</span>
                      <span className="chat-text">{message.message}</span>
                    </div>
                  ))}
                </div>
                <form onSubmit={(e) => handleAnswer(e, chatMessage)}>
                  <input className="chat-input"
                    type="text"
                    placeholder="Tu respuesta"
                    onChange={(e) => setChatMessage(e.target.value)}
                    value={chatMessage}
                  />
                  <button className="1" type="submit">Enviar</button>
                </form>
              </div>              
              ) : question_type === 'button' ? (
                <div className="options-container">
                  {options_final.map((option, index) => (
                    <button
                      key={index}
                      className={`option-button ${chatMessage === (index + 1) ? 'selected' : ''}`}
                      onClick={(e) => handleAnswer(e, index + 1)} 
                      disabled={button_disabled}
                    >
                      {option}
                    </button>
                  ))}
                </div>
              ) : question_type === 'text' ? (
                <div className="text-container">
                    <input
                      type="text"
                      placeholder="Tu respuesta"
                      onChange={(e) => setChatMessage(e.target.value)}
                    />
                    <button type="submit" className = "option-button" disabled = {button_disabled} onClick={(e) => handleAnswer(e, chatMessage)}>Enviar</button>
                  </div>
              ) : null}
            </div>
            <div className = "ranking-container">
              <h1 className="medieval-subtitle">Ranking</h1>
              <ul className="ranking-list">
                {ranking.map((player, index) => (
                  <li key={index}>{player.username} {player.score}</li>
                ))}
              </ul>
            </div>
        </div>
      ) : state === 'end' ? (
        <div className = "end-container">
          <h1 className="medieval-title">Dungeons & Trivia 123 Winners</h1>
          <h1 className="medieval-subtitle">Ranking</h1>
          <ul className="ranking-list">
            {winners.map((winner, index) => (
              <li key={index}>{winner.username} {winner.score}</li>
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  );

}  

export default App;
